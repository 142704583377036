import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import libImg1 from '../../assets/images/lib-template1.png';
import libImg2 from '../../assets/images/lib-template2.png';
import libImg3 from "../../assets/images/lib-template3.png";
import libImg4 from "../../assets/images/lib-template4.png";
import starGroup from '../../assets/svg/digital-start-gr.svg';
import grassIcon from '../../assets/svg/grass.svg';
import blobLeftIcon from '../../assets/svg/digital-blob-left.svg';

const libraryTemplateData = [
    {
        id: 1,
        img: libImg1,
        title: 'Daily Planners',
        content:
            'With a digital planner, you can easily track your tasks and schedule, set reminders, and receive notifications to stay on top of your day-to-day activities. help you streamline your workflow, reduce stress, and improve productivity.',
    },
    {
        id: 2,
        img: libImg2,
        title: 'Weekly Planner',
        content:
            'A digital weekly planner can help you plan and prioritize tasks for the week ahead, set reminders, and receive notifications to ensure that you stay on track with your goals. It also allows you to easily adjust your schedule if unexpected events arise or if priorities change.',
    },
    {
        id: 3,
        img: libImg3,
        title: 'Monthly Planner',
        content:
            'A digital monthly planner can help you identify and prioritize tasks and goals for the month ahead, which can improve your productivity and reduce stress.With a digital monthly planner, you can easily track progress towards your goals and measure your accomplishments at the end of each month',
    },
    {
        id: 4,
        img: libImg4,
        title: 'Yearly Planners',
        content:
            'A digital yearly planner can help you break down long-term goals into manageable tasks and track progress towards achieving them throughout the year. This can help you stay motivated and focused on achieving your objectives',
    },
];

const LibraryTemplate = () => {
    return (
        <section className={styles['library__template']}>
            <h1 className={clsx('feature__title')}>Templates</h1>
            <div className={styles['library__template--list']}>
                {libraryTemplateData.map((data) => (
                    <div key={data.id} className={styles['library__template--item']}>
                        <img src={data.img} alt="" />
                        <h5>{data.title}</h5>
                        <p>{data.content}</p>
                    </div>
                ))}
            </div>
            <div className={styles['library__template--star']}>
                <img src={starGroup} alt="" />
            </div>
            <div className={styles['library__template--grass']}>
                <img src={grassIcon} alt="" />
            </div>
            <div className={styles['library__template--blob--left']}>
                <img src={blobLeftIcon} alt="" />
            </div>
        </section>
    );
};

export default LibraryTemplate;
