import React from 'react';
import Header from '../../components/Header';
import Blog from '../../components/Journal/Blog';
import Footer from '../../components/Footer';

const BlogPage = () => {
    return (
        <>
            <Header />
            <Blog />
            <Footer />
        </>
    );
};

export default BlogPage;
