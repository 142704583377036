import React from 'react'
import styles from './Features.module.scss'
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

function Features() {
    return (
        <section className={cx('features')}>
            <h4>Features you’ll love</h4>
            <div className={cx('cards')}>
                <div className={cx('card', 'cardRed')}>
                    <div className={cx('number')}>
                        <img src='/imgs/mail/numbers/1.svg' alt='number' />
                    </div>
                    <p>Secure with Face ID & passcode</p>
                </div>
                <div className={cx('card', 'cardYellow')}>
                    <div className={cx('number')}>
                        <img src='/imgs/mail/numbers/2.svg' alt='number' />
                    </div>
                    <p>Block unwanted emails</p>
                </div>
                <div className={cx('card', 'cardSprint')}>
                    <div className={cx('number')}>
                        <img src='/imgs/mail/numbers/3.svg' alt='number' />
                    </div>
                    <p>Unsubsciption with one tap</p>
                </div>
                <div className={cx('card', 'cardBlue')}>
                    <div className={cx('number')}>
                        <img src='/imgs/mail/numbers/4.svg' alt='number' />
                    </div>
                    <p>Create events to reply emails</p>
                </div>
                <div className={cx('card', 'cardGreen')}>
                    <div className={cx('number')}>
                        <img src='/imgs/mail/numbers/5.svg' alt='number' />
                    </div>
                    <p>Impressive decoration</p>
                </div>
                <div className={cx('card', 'cardPurple')}>
                    <div className={cx('number')}>
                        <img src='/imgs/mail/numbers/6.svg' alt='number' />
                    </div>
                    <p>Darkmode & lightmode</p>
                </div>
            </div>
        </section>
    )
}

export default Features