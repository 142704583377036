import React from 'react';
import styles from './style.module.scss';

const Feature = () => {
    return (
        <>
            <div className={styles['root']}>
                <div className={styles['root__banner']}>
                    <div className={styles['root__banner--title']}>
                        The more useful features, the more efficient plans
                    </div>
                    <div className={styles['root__banner--des']}>
                        Updating the best features helps you plan professionally and efficiently
                    </div>
                </div>
            </div>
        </>
    );
};

export default Feature;
