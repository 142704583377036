import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import VideoAdImg1 from '../../assets/images/video-advertise1.png';
import VideoAdImg2 from '../../assets/images/video-advertise2.png';
import VideoAdImg3 from "../../assets/images/video-advertise3.png";
import video1 from '../../assets/videos/1.mp4';
import video2 from '../../assets/videos/2.mp4';
import video3 from '../../assets/videos/3.mp4';
import playIcon from '../../assets/svg/play-icon.svg';

const videoAdData = [
    {
        id: 1,
        thumb: VideoAdImg1,
        video: video1,
    },
    {
        id: 2,
        thumb: VideoAdImg2,
        video: video2,
    },
    {
        id: 3,
        thumb: VideoAdImg3,
        video: video3,
    },
];

const VideoAdvertise = () => {
    const [playingIndex, setPlayingIndex] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const videoRef = useRef(null);

    const handlePlay = (index) => {
        setPlayingIndex(index);
        setShowOverlay(true);
    };

    const handlePause = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
        setPlayingIndex(null);
        setShowOverlay(false);
    };

    const handleVideoLoaded = () => {
        videoRef.current.play();
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (showOverlay && event.keyCode === 27) {
                handlePause();
            }
        };

        if (showOverlay) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showOverlay]);

    return (
        <section className={styles['video__advertise']}>
            <h1 className={clsx('feature__title', styles['video__advertise--title'])}>Enjoy all features</h1>
            <div className={styles['video__advertise--list']}>
                {videoAdData.map((video, index) => (
                    <div key={video.id} className={styles['video__advertise--item']}>
                        {playingIndex === index ? (
                            <div className={styles['video__container']}>
                                <video
                                    controls
                                    ref={videoRef}
                                    id={`video-${video.id}`}
                                    onLoadedData={handleVideoLoaded}
                                    className={styles['video__container--item']}
                                >
                                    <source src={video.video} type="video/mp4" />
                                </video>
                            </div>
                        ) : (
                            <img src={video.thumb} alt="" />
                        )}
                        <div className={styles['video__advertise--play']}>
                            {playingIndex !== index && (
                                <img src={playIcon} alt="" className="" onClick={() => handlePlay(index)} />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default VideoAdvertise;
