import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import logoImg from '../../assets/svg/logo.svg';
import { Link } from 'react-router-dom';

const Organize = () => {
    return (
        <section className={styles['organize']}>
            <h1 className={styles['organize__title']}>Organize Your Life</h1>
            <p className={styles['organize__text']}>
                The ultimate digital planner for organizing your life, <br /> reaching your goals & achieving productivity
            </p>
            <div className={styles['organize__download--flex']}>
                <img src={logoImg} alt="" />
                <div className={styles['organize__download--line']}></div>
                <Link to={'https://apps.apple.com/us/app/id1664348099'}>
                    <button className={clsx('btn--download')}>Free download</button>
                </Link>
            </div>
        </section>
    );
};

export default Organize;
