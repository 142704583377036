import React from 'react'
import styles from './Feedback.module.scss'

import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import classNames from 'classnames/bind';

const cx = classNames.bind(styles)

function Feedback() {

    SwiperCore.use([Autoplay]);

    return (
        <section className={cx('feedback')}>
            <h4>Feedbacks</h4>
            <div className={cx('cards')}>
                <div className={cx('card')}>
                    <div className={cx('header')}>
                        <img src='/imgs/mail/avatar1.svg' alt='avatar' />
                        <div className={cx('name')}>
                            <p>Nicolas Adam</p>
                            <span>America</span>
                        </div>
                    </div>
                    <p className={cx('content')}>
                        Everything I could want easy to use. All my addresses in one spot. So I 
                        open one app and check all my email addresses, no confusion at all just now 
                        being able to block in one tap. Great job 
                    </p>
                </div>
                <div className={cx('card')}>
                    <div className={cx('header')}>
                        <img src='/imgs/mail/avatar2.svg' alt='avatar' />
                        <div className={cx('name')}>
                            <p>Tom Holland</p>
                            <span>Canada</span>
                        </div>
                    </div>
                    <p className={cx('content')}>
                        You can block and unsubscribe to any email very easily and has tons 
                        of useful tools. You need this app!
                    </p>
                </div>
            </div>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: true,
                }}
                loop={true}
                centeredSlides={true}
                modules={[Pagination,Autoplay]}
                className={cx('card-mb')}
            >
                <SwiperSlide>
                    <div className={cx('card')}>
                        <div className={cx('header')}>
                            <img src='/imgs/mail/avatar3.svg' alt='avatar' />
                            <div className={cx('name')}>
                                <p>Alina Tommy</p>
                                <span>America</span>
                            </div>
                        </div>
                        <p className={cx('content')}>
                            I’ve had wonderful experience using your app. It is very easy to use, keep up the good work
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={cx('card')}>
                        <div className={cx('header')}>
                            <img src='/imgs/mail/avatar1.svg' alt='avatar' />
                            <div className={cx('name')}>
                                <p>Nicolas Adam</p>
                                <span>America</span>
                            </div>
                        </div>
                        <p className={cx('content')}>
                            Everything I could want easy to use. All my addresses in one spot. So I 
                            open one app and check all my email addresses, no confusion at all just now 
                            being able to block in one tap. Great job 
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={cx('card')}>
                        <div className={cx('header')}>
                            <img src='/imgs/mail/avatar2.svg' alt='avatar' />
                            <div className={cx('name')}>
                                <p>Tom Holland</p>
                                <span>Canada</span>
                            </div>
                        </div>
                        <p className={cx('content')}>
                            You can block and unsubscribe to any email very easily and has tons 
                            of useful tools. You need this app!
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
}

export default Feedback