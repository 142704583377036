import React from "react";

import styles from "./styles.module.scss";
import digitalBlob from "../../assets/svg/digital-blob.svg";
import digitalBlobLeft from "../../assets/svg/digital-blob-left.svg";
import digitalImg from "../../assets/images/digital-img.svg";
import blobStar from "../../assets/svg/digital-start-gr.svg";
import blobTree from "../../assets/svg/digital-tree.svg";
import clsx from "clsx";

const Digital = () => {
  return (
    <section className={styles["digital"]}>
      <div className={clsx("wrap-res", styles["digital__content"])}>
        <div className={styles["digital__content--text"]}>
          <h3 className={clsx("digital__title")}>
            Stay on top of your day with a Digital Daily Planner
          </h3>
          <p className={clsx("digital__description")}>
            Start the day by reviewing your to-do list and prioritizing tasks
            based on what needs to be done first.
          </p>
        </div>
        <div className={styles["digital__content--img"]}>
          <img src={digitalImg} alt="" />
        </div>
      </div>

      <div className={styles["digital__blob"]}>
        <img src={digitalBlob} alt="" />
      </div>
      <div className={styles["digital__blob--left"]}>
        <img src={digitalBlobLeft} alt="" />
      </div>
      <div className={styles["digital__blob--star"]}>
        <img src={blobStar} alt="" />
      </div>
      <div className={styles["digital__blob--tree"]}>
        <img src={blobTree} alt="" />
      </div>
    </section>
  );
};

export default Digital;
