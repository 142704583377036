import React from 'react';
import Header from '../../components/Header';
import HighlightSection from '../../components/HightlightSection';
import styles from './styles.module.scss';
import LibraryTemplate from '../../components/LibraryTemplate';
import VideoAdvertise from '../../components/VideoAdvertise';
import Footer from '../../components/Footer';

const Library = () => {
    return (
        <>
            <div className={styles['library']}>
                <Header />
                <HighlightSection />
                <LibraryTemplate />
                <VideoAdvertise />
                <Footer />
            </div>
        </>
    );
};

export default Library;
