import { Routes, Route } from "react-router-dom";
import Home from "./pages";
import Kebo from "./pages/kebo";
import Content from "./components/home/privatepolicy";
import Termsofuse from "./components/home/termsofuse";
import Calendar from "./pages/calendar";
import CalendarTermofuse from "./components/calendar/termofuse";
import CalendarPrivatepolicy from "./components/calendar/privatePolicy";
import DirectStore from "./components/directstore";
import CalendarAndroidPrivatepolicy from "./components/calendar/androidprivatepolicy";
import CalendarAndroidTermofuse from "./components/calendar/androidtermsofuse";
import Mail from "./pages/mail";
import HomeJournal from "./pages/HomeJournal";
import Library from "./pages/Library";
import FeaturePage from "./pages/FeaturePage";
import BlogPage from "./pages/BlogPage";
import PricingPage from "./pages/PricingPage";
import JournalStorePage from "./pages/JournalStorePage";

function App() {
	const directStoreRoute = ["/learnkanji", "/jlpttest", "/goethetest", "/cal"];
	function Redirect() {
		const currentOs = getMobileOperatingSystem();
		if (currentOs === "Android") {
			window.location.replace(
				"https://play.google.com/store/apps/details?id=journal.notes.planner.starnest"
			);
			// window.location =
			// 	"https://play.google.com/store/apps/details?id=journal.notes.planner.starnest";
		}
		if (currentOs === "iOS") {
			// window.location =
			// 	"https://apps.apple.com/us/app/journal-digital-planner-2024/id1664348099";
			window.location.replace(
				"https://apps.apple.com/us/app/journal-digital-planner-2024/id1664348099"
			);
		}
	}
	function getMobileOperatingSystem() {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;
		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return "Windows Phone";
		}

		if (/android/i.test(userAgent) || /windows/i.test(userAgent)) {
			return "Android";
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (
			/iPad|iPhone|iPod/.test(userAgent) ||
			(/macintosh/i.test(userAgent) && !window.MSStream)
		) {
			return "iOS";
		}

		return "unknown";
	}
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/journal" element={<HomeJournal />} />
			<Route path="/journal/library" element={<Library />} />
			<Route path="/journal/feature" element={<FeaturePage />} />
			<Route path="/journal/blog" element={<BlogPage />} />
			<Route path="/journal/pricing" element={<PricingPage />} />
			<Route path="/journalmkt" element={<JournalStorePage />} />
			<Route path="/kebo" element={<Kebo />} />
			<Route path="kebo/privatepolicy" element={<Content />} />
			<Route path="kebo/termsofuse" element={<Termsofuse />} />
			<Route path="/calendar" element={<Calendar />} />
			<Route path="/calendar/termsofuse" element={<CalendarTermofuse />} />
			<Route path="journalplannermkt" element={<Redirect />} />
			<Route
				path="/calendar/privatepolicy"
				element={<CalendarPrivatepolicy />}
			/>
			<Route
				path="/calendar/androidprivatepolicy"
				element={<CalendarAndroidPrivatepolicy />}
			/>
			<Route
				path="/calendar/androidtermsofuse"
				element={<CalendarAndroidTermofuse />}
			/>
			{directStoreRoute.map((path) => (
				<Route key={path} path={path} element={<DirectStore />} />
			))}

			<Route path="/mail" element={<Mail />} />
		</Routes>
	);
}

export default App;
