import React from 'react';

import styles from './styles.module.scss';
import template1 from "../../assets/images/template1.png";
import template2 from "../../assets/images/template2.png";
import template3 from "../../assets/images/template3.png";
import template4 from "../../assets/images/template4.png";
import template5 from "../../assets/images/template5.png";
import template6 from "../../assets/images/template6.png";
import template7 from "../../assets/images/template7.png";
import template8 from "../../assets/images/template8.png";
import template9 from "../../assets/images/template9.png";
import template10 from "../../assets/images/template10.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import 'swiper/swiper.scss';
import styled from '@emotion/styled';
import clsx from 'clsx';

const dataTemplate = [
    {
        id: 1,
        img: template1,
    },
    {
        id: 2,
        img: template2,
    },
    {
        id: 3,
        img: template3,
    },
    {
        id: 4,
        img: template4,
    },
    {
        id: 5,
        img: template5,
    },
    {
        id: 6,
        img: template6,
    },
    {
        id: 7,
        img: template7,
    },
    {
        id: 8,
        img: template8,
    },
    {
        id: 9,
        img: template9,
    },
    {
        id: 10,
        img: template10,
    },
];

const StyledSwiper = styled(Swiper)`
    .swiper-slide {
        /* width: 252px !important; */
    }

    @media only screen and (min-width: 320px) and (max-width: 598px) {
        .swiper-slide {
            width: 143px !important;
        }
    }
`;

const TemplateSection = () => {
    return (
        <section className={clsx('template', styles['template'])}>
            <div className={styles['template__list']}>
                <StyledSwiper
                    slidesPerView={2.2}
                    spaceBetween={27}
                    autoplay={{
                        delay: 2500,
                    }}
                    loop={true}
                    loopedSlides={3}
                    initialSlide={7}
                    breakpoints={{
                        640: {
                            slidesPerView: 1.8,
                            spaceBetween: 27,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 6,
                            spaceBetween: 48,
                        },
                    }}
                    centeredSlides={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                >
                    {dataTemplate.map((template) => (
                        <SwiperSlide key={template.id}>
                            <div className={styles['template__item']}>
                                <img src={template.img} alt="" />
                            </div>
                        </SwiperSlide>
                    ))}
                </StyledSwiper>
            </div>
        </section>
    );
};

export default TemplateSection;
