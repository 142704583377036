import React from 'react';
import Header from '../../components/Header';
import Organize from '../../components/OrganizeSection';
import Digital from '../../components/DigitalSection';
import FunctionSection from '../../components/FunctionSection';
import WeeklyPlanner from '../../components/WeeklyPlannerSection';
import MonthlyPlanner from '../../components/MonthlyPlannerSection';
import HabitTrackerSection from '../../components/HabitTrackerSection';
import TemplateSection from '../../components/TemplateSection';
import FeatureSection from '../../components/FeatureSection';
import TutorialSection from '../../components/TutorialSection';
import CustomerReview from '../../components/CustommerReview';
import HighlightSection from '../../components/HightlightSection';
import Footer from '../../components/Footer';

const HomeJournal = () => {
    return (
        <div>
            <Header />
            <Organize />
            <Digital />
            <FunctionSection />
            <WeeklyPlanner />
            <MonthlyPlanner />
            <HabitTrackerSection />
            <TemplateSection />
            <FeatureSection />
            <TutorialSection />
            <CustomerReview />
            <HighlightSection />
            <Footer />
        </div>
    );
};

export default HomeJournal;
