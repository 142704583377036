import React from "react";
import Keyboard from "../components/keyboard";

const Kedo = () => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Keyboard></Keyboard>
    </div>
  );
};

export default Kedo;
