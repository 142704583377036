import React from 'react';

import styles from './styles.module.scss';
import shareIcon from '../../assets/svg/share.svg';
import studioIcon from '../../assets/svg/studio.svg';
import stuffIcon from '../../assets/svg/stuff.svg';

const FunctionSection = () => {
    return (
        <section className={styles['function']}>
            <div className={styles['function__list']}>
                <div className={styles['function__item']}>
                    <div className={styles['function__item--icon']}>
                        <img src={shareIcon} alt="" />
                    </div>
                    <h5>share</h5>
                    <p>
                        Share your plans with family and friends to discuss together and connect with each other
                    </p>
                </div>
                <div className={styles['function__item']}>
                    <div className={styles['function__item--icon']}>
                        <img src={studioIcon} alt="" />
                    </div>
                    <h5>studio</h5>
                    <p>
                        Various template, stickers, layouts to hepl you make planner
                    </p>
                </div>
                <div className={styles['function__item']}>
                    <div className={styles['function__item--icon']}>
                        <img src={stuffIcon} alt="" />
                    </div>
                    <h5>my stuff</h5>
                    <p>
                        Easily organize & save the stuff you love
                    </p>
                </div>
            </div>
        </section>
    );
};

export default FunctionSection;
