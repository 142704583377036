import React, { useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import classNames from "classnames/bind";
import sendEmail from "../../../apis/mail";
import { validateFormContact } from "../../../util/validateContactForm";

const cx = classNames.bind(styles);

function Footer() {
  const [formValues, setFormValues] = useState({
    email: "",
    content: "",
  });

  const handleInputChange = (event) => {
    setFormValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const [message, setMessage] = useState({
    message: "",
    type: "NONE",
  });

  const handleSubmit = () => {
    if (validateFormContact(formValues)) {
      sendEmail({
        ...formValues,
        app_name: "All in one email",
      });
      setMessage({
        message: "We have received your contact",
        type: "SUCCESS",
      });
      setFormValues({
        email: "",
        content: "",
      });
    } else {
      setMessage({
        message:
          "Please input your email and content (not greater than 5000 characters).",
        type: "ERROR",
      });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMessage({
        message: "",
        type: "NONE",
      });
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [message]);

  return (
    <footer id="contact-us" className={cx("footer")}>
      <h3>ALL IN ONE MAIL</h3>
      <div className={cx("contact")}>
        <div className={cx("form")}>
          <p>Contact us</p>
          <input
            type="email"
            name="email"
            placeholder="Your email"
            className={cx("input")}
            value={formValues.email}
            onChange={handleInputChange}
          />
          <textarea
            placeholder="Content"
            name="content"
            className={cx("input")}
            onChange={handleInputChange}
            value={formValues.content}
          ></textarea>
          <button onClick={handleSubmit}>Send</button>
          <p
            className={cx("form_message", { error: message.type === "ERROR" })}
          >
            {message.message}
          </p>
        </div>
        <img src="/imgs/mail/footer.svg" alt="footer" />
      </div>
    </footer>
  );
}

export default Footer;
