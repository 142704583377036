import React from 'react'
import styles from './Services.module.scss'
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

function Services() {
    return (
        <section className={cx('services')}>
            <div className={cx('wrapper')}>
                <div className={cx('content')}>
                    <h3>One app, all email accounts</h3>
                    <p>All of your accounts will be managed in one place, you can easily switch between accounts</p>
                </div>
                <div className={cx('img')}>
                    <img src='/imgs/mail/phone1.svg' alt='phone' className={cx('phone')} />
                    <img src='/imgs/mail/icons/google.svg' alt='google' className={cx('google')} />
                    <img src='/imgs/mail/icons/outlook.svg' alt='outlook' className={cx('outlook')} />
                    <img src='/imgs/mail/icons/office.svg' alt='office' className={cx('office')} />
                    <img src='/imgs/mail/icons/yahoo.svg' alt='yahoo' className={cx('yahoo')} />
                </div>
            </div>
            <div className={cx('wrapper')}>
                <div className={cx('img')}>
                    <img src='/imgs/mail/phone2.svg' alt='phone' className={cx('phone')} />
                    <img src='/imgs/mail/status/inbox.svg' alt='inbox' className={cx('inbox')} />
                </div>
                <div className={cx('content')}>
                    <h3>Classify files in detail and smart</h3>
                    <p>
                        Organize your files into specific categories or folders with a high
                        level of accuracy and intelligence
                    </p>
                </div>
            </div>
            <div className={cx('wrapper')}>
                <div className={cx('content')}>
                    <h3>Classify files in detail and smart</h3>
                    <p>
                        Organize your files into specific categories or folders with a high
                        level of accuracy and intelligence
                    </p>
                </div>
                <div className={cx('img')}>
                    <img src='/imgs/mail/phone3.svg' alt='phone' className={cx('phone')} />
                    <img src='/imgs/mail/status/message.svg' alt='message' className={cx('message')} />
                </div>
            </div>
            <div className={cx('wrapper')}>
                <div className={cx('img')}>
                    <img src='/imgs/mail/phone4.svg' alt='phone' className={cx('phone')} />
                    <img src='/imgs/mail/status/cancel.svg' alt='cancel' className={cx('cancel')} />
                </div>
                <div className={cx('content')}>
                    <h3>Block spam & Unsubscribe  with one tap</h3>
                    <p>
                        Help you filter out the noise, you won't be bothered by irrelevant emails, reducing clutter in their inbox, saving time and effort
                    </p>
                </div>
            </div>
            <div className={cx('wrapper')}>
                <div className={cx('content')}>
                    <h3>Build - in calendar</h3>
                    <p>
                        Create events to respond to emails, create tasks, and take notes.
                    </p>
                </div>
                <div className={cx('img')}>
                    <img src='/imgs/mail/phone5.svg' alt='phone' className={cx('phone')} />
                    <img src='/imgs/mail/status/setting.svg' alt='setting' className={cx('setting')} />
                </div>
            </div>
        </section>
    )
}

export default Services