import React from "react";
import { Card } from "antd";
import styles from "./style.module.scss";
import imgBlog from "../../../../assets/images/Blog/imgBlog.png";

const { Meta } = Card;
const WhatNew = () => {
  const cardData = [
    {
      title: "Daily planners",
      description: "Notes are the beginning.",
      description2:
        "Create and annotate anything from study guides to storyboards.",
      imageSrc: imgBlog,
    },
    {
      title: "Daily planners",
      description:
        "Notes are the beginning.",
      description2:
        "Create and annotate anything from study guides to storyboards.",
      imageSrc: imgBlog,
    },
    {
      title: "Daily planners",
      description:
        "Notes are the beginning.",
      description2:
        "Create and annotate anything from study guides to storyboards.",
      imageSrc: imgBlog,
    },
    {
      title: "Daily planners",
      description:
        "Notes are the beginning.",
      description2:
        "Create and annotate anything from study guides to storyboards.",
      imageSrc: imgBlog,
    },
    {
      title: "Daily planners",
      description:
        "Notes are the beginning.",
      description2:
        "Create and annotate anything from study guides to storyboards.",
      imageSrc: imgBlog,
    },
    {
      title: "Daily planners",
      description:
        "Notes are the beginning.",
      description2:
        "Create and annotate anything from study guides to storyboards.",
      imageSrc: imgBlog,
    },
  ];
  const cardList = cardData.map((card, index) => (
    <div>
      <Card
        key={index}
        hoverable
        cover={<img alt={card.title} src={card.imageSrc} />}
      >
        <Meta
          title={card.title}
          description={[
            <div>
              <p>{card.description}</p>
              <p>{card.description2}</p>
            </div>
          ]}
        />
      </Card>
    </div>
  ));
  return (
    <>
      <div className={styles["content"]}>
        <div className={styles["content__title"]}>What’s new</div>
        <div className={styles["content__cardList"]}>{cardList}</div>
      </div>
    </>
  );
};

export default WhatNew;
