import React from "react";
import JournalStore from "../../components/Journal/Store";

const JournalStorePage = () => {
  return (
    <>
      <JournalStore />
    </>
  );
};

export default JournalStorePage;
