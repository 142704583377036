import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/swiper.scss";
import styled from "@emotion/styled";

import styles from "./styles.module.scss";

// img
import tutorialImg from "../../assets/images/tutorial.png";
import basicImg from "../../assets/images/basic-tutorial.png";
import changePageImg from "../../assets/images/change-page-tutorial.png";
import dragImg from "../../assets/images/drag-tutorial.png";
import eventImg from "../../assets/images/event-tutorial.png";
import fullImg from "../../assets/images/full-tutorial.png";
import groupImg from "../../assets/images/group-tutorial.png";
import linkPageImg from "../../assets/images/link-page-tutorial.png";
import linkURLImg from "../../assets/images/link-url-tutorial.png";
import pdfImg from "../../assets/images/pdf-tutorial.png";

// videos
import musicAndSoundIcon from "../../assets/svg/music-and-sound.svg";
import AddTemplateVideo from "../../assets/videos/Add templates for Journal.mp4";
import ChangePageVideo from "../../assets/videos/Change page style Journal.mp4";
import DragDropVideo from "../../assets/videos/Drag and drop image from internet.mp4";
import GroupObjVideo from "../../assets/videos/Group Object for Journal.mp4";
import LinkObjVideo from "../../assets/videos/Link Object to Event.mp4";
import LinkPageVideo from "../../assets/videos/Link to Page.mp4";
import LinkURLVideo from "../../assets/videos/Link to URL.mp4";
import BasicVideo from "../../assets/videos/Start creating a basic Journal.mp4";
import FullVideo from "../../assets/videos/Start creating a Full Journal.mp4";
import PDFVideo from "../../assets/videos/Start creating a PDF Journal.mp4";

const StyledSwiper = styled(Swiper)`
  .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper.css-1wr7zb8 {
    border-radius: 20px;
  }

  .swiper-wrapper {
    border-radius: 20px;
    padding-bottom: 80px;
  }

  .swiper-slide {
    width: 356px !important;
  }

  @media only screen and (min-width: 320px) and (max-width: 598px) {
    .swiper-slide {
      width: 210px !important;
    }
    .swiper-wrapper {
      padding: 20px 0;
    }
  }
`;

const tutorialData = [
  {
    id: 1,
    img: tutorialImg,
    video: AddTemplateVideo,
    tittle: "Add templates for Journal",
  },
  {
    id: 2,
    img: changePageImg,
    video: ChangePageVideo,
    tittle: "Change page style Journal",
  },
  {
    id: 3,
    img: groupImg,
    video: GroupObjVideo,
    tittle: "Group Object for Journal",
  },
  {
    id: 4,
    img: eventImg,
    video: LinkObjVideo,
    tittle: "Link Object to Event",
  },
  {
    id: 5,
    img: dragImg,
    video: DragDropVideo,
    tittle: "Drag and drop image from internet",
  },
  {
    id: 6,
    img: linkPageImg,
    video: LinkPageVideo,
    tittle: "Link to Page",
  },
  {
    id: 7,
    img: linkURLImg,
    video: LinkURLVideo,
    tittle: "Link to URL",
  },
  {
    id: 8,
    img: basicImg,
    video: BasicVideo,
    tittle: "Start creating a basic Journal",
  },
  {
    id: 9,
    img: fullImg,
    video: FullVideo,
    tittle: "Start creating a full Journal",
  },
  {
    id: 10,
    img: pdfImg,
    video: PDFVideo,
    tittle: "Start creating a PDF Journalt",
  },
];

const TutorialSection = () => {
  const [playingIndex, setPlayingIndex] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = (index) => {
    setPlayingIndex(index);
    setShowOverlay(true);
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setPlayingIndex(null);
    setShowOverlay(false);
  };

  const handleVideoLoaded = () => {
    videoRef.current.play();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (showOverlay && event.keyCode === 27) {
        handlePause();
      }
    };

    if (showOverlay) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showOverlay]);

  return (
    <section className={styles["tutorial"]}>
      <h1 className={clsx("feature__title")}>Tutorial</h1>
      <div className={styles["tutorial__list"]}>
        <StyledSwiper
            mousewheel={true}
            spaceBetween={16}
            slidesPerView={2.2}
            autoplay={{
              delay: 2500,
            }}
            loop={true}
            loopedSlides={2}
            initialSlide={7}
            breakpoints={{
                640: {
                    slidesPerView: 1.8,
                    spaceBetween: 16,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 61,
                },
            }}
            centeredSlides={true}
            modules={[Autoplay]}
            className="mySwiper"
        >
          {tutorialData.map((tutorial, index) => (
            <SwiperSlide key={tutorial.id}>
              <div className={styles["tutorial__item"]}>
                <div className={styles["tutorial__item--img"]}>
                  {playingIndex === index ? (
                    <div className={styles["video__container"]}>
                      <video
                        controls
                        ref={videoRef}
                        id={`video-${tutorial.id}`}
                        onLoadedData={handleVideoLoaded}
                        width="100%"
                        height="100%"
                        style={{ maxHeight: "365px" }}
                      >
                        <source src={tutorial.video} type="video/mp4" />
                      </video>
                    </div>
                  ) : (
                    <img src={tutorial.img} alt="" />
                  )}
                  <p>{tutorial.tittle}</p>

                  <div className={styles["tutorial__item--svg"]}>
                    {playingIndex === index ? (
                      <img
                        src={null}
                        alt=""
                        className=""
                        onClick={handlePause}
                      />
                    ) : (
                      <img
                        src={musicAndSoundIcon}
                        alt=""
                        className=""
                        onClick={() => handlePlay(index)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </div>
    </section>
  );
};

export default TutorialSection;
