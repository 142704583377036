import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import monthlyPlanner from '../../assets/images/monthly-planner.png';
import monthlyBlob from '../../assets/svg/digital-blob-left.svg';
import monthlyGrass from '../../assets/svg/grass.svg';

const MonthlyPlanner = () => {
    return (
        <section className={clsx('wrap-res', styles['monthly__planner'])}>
            <div className={styles['monthly__planner--img']}>
                <img src={monthlyPlanner} alt="" />
            </div>
            <div className={styles['monthly__planner--text']}>
                <h3 className={clsx('digital__title')}>Efficiently plan tasks with Monthly Planner</h3>
                <p className={clsx('digital__description')}>
                    Start by setting your overall goals and priorities for the month. A monthly planner helps you
                    organize time and tasks throughout the month. It allows you to set goals and deadlines for
                    specific tasks and events.
                </p>
            </div>
            <div className={styles['monthly__planner--blob']}>
                <img src={monthlyBlob} alt="" />
            </div>
            <div className={styles['monthly__planner--grass']}>
                <img src={monthlyGrass} alt="" />
            </div>
        </section>
    );
};

export default MonthlyPlanner;
