import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import facebookIcon from "../../assets/svg/facebookIcon.svg";
import instagramIcon from "../../assets/svg/instagramIcon.svg";
import youtubeIcon from "../../assets/svg/youtubeIcon.svg";
import tiktokIcon from "../../assets/svg/tiktokIcon.svg";
import clsx from "clsx";
import sendEmail from "../../apis/mail";
import { validateFormContact } from "../../util/validateContactForm";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";

const cx = classNames.bind(styles);
function Footer() {
  const [formValues, setFormValues] = useState({
    email: "",
    content: "",
  });

  const handleInputChange = (event) => {
    setFormValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const [message, setMessage] = useState({
    message: "",
    type: "NONE",
  });

  const handleSubmit = () => {
    if (validateFormContact(formValues)) {
      sendEmail({
        ...formValues,
        app_name: "Journal Planner",
      });
      setMessage({
        message: "We have received your contact",
        type: "SUCCESS",
      });
      setFormValues({
        email: "",
        content: "",
      });
    } else {
      setMessage({
        message:
          "Please input your email and content (not greater than 5000 characters).",
        type: "ERROR",
      });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMessage({
        message: "",
        type: "NONE",
      });
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [message]);
  return (
    <section className={styles["footer"]}>
      <div className={clsx(styles["footer__list"], "wrap-res")}>
        <div className={styles["footer__list--download"]}>
          <h5>Download</h5>
          <Link to={"https://apps.apple.com/us/app/id1664348099"}>
            <p>Journal and Planner for iOS/iPadOS</p>
          </Link>
          <Link to={"https://apps.apple.com/us/app/id1664348099"}>
            <p>Journal and Planner for Mac</p>
          </Link>
        </div>
        <div className={styles["footer__list--product"]}>
          <h5>Product</h5>
          <Link to="/journal/feature">
            <p>Feature</p>
          </Link>
          <Link to="/journal/library">
            <p>Library</p>
          </Link>
          <Link to="/journal/blog">
            <p>Blog</p>
          </Link>
        </div>
        <div className={styles["footer__list--support"]}>
          <h5>Support</h5>
          <Link to={"https://starnestsolution.com/calendar/privatepolicy"}>
            <p>Privacy Policy</p>
          </Link>
          <Link to={"https://sites.google.com/view/journaltermsofuse/home"}>
            <p>Terms & Conditions</p>
          </Link>
          <p>Cookies </p>
        </div>
        <div className={styles["footer__list--contact"]}>
          <div className={styles["footer__contact"]}>
            <h5>Contact us</h5>
            <div className={styles["footer__contact--flex"]}>
              <div className={styles["footer__contact--input"]}>
                <input
                  type="text"
                  name="email"
                  className={styles["input_border"]}
                  placeholder="Your email"
                  value={formValues.email}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="content"
                  className={styles["input_border"]}
                  placeholder="Content"
                  onChange={handleInputChange}
                  value={formValues.content}
                />
                <p
                  className={cx("form_message", {
                    error: message.type === "ERROR",
                  })}
                >
                  {message.message}
                </p>
              </div>
              <button
                onClick={handleSubmit}
                className={clsx("btn--download", styles["btn__send"])}
              >
                send
              </button>
            </div>
          </div>
          <div className={styles["footer__social"]}>
            <h4 className={styles["footer__social--title"]}>Follow us</h4>
            <div className={styles["footer__social--list"]}>
              <Link to={"https://www.facebook.com/journalplanner2023"}>
                <img src={facebookIcon} alt="" />
              </Link>
              <Link to={"https://www.instagram.com/journal_planner.app2023/"}>
                <img src={instagramIcon} alt="" />
              </Link>
              <Link
                to={"https://www.youtube.com/channel/UCl6HgxZ56hZE3jO-ESEqm9g"}
              >
                <img src={youtubeIcon} alt="" />
              </Link>
              <Link>
                <img src={tiktokIcon} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
