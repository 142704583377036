import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "swiper/swiper.scss";

import styles from "./styles.module.scss";
import customer1 from "../../assets/svg/cus1.svg";
import customer2 from "../../assets/svg/cus2.svg";
import customer3 from "../../assets/svg/cus3.svg";
import styled from "@emotion/styled";

const reviewData = [
  {
    id: 1,
    cus_avt: customer1,
    impress_text: "Great way for students to help each other",
    comment:
      "I’ve found that the Journal and Planner Community not only helps in my field of study but allows me to explore other areas that I’m interested in. It’s such a great way for students to help each other as it provides different variations and perspectives on certain topics.",
  },
  {
    id: 2,
    cus_avt: customer2,
    impress_text: "Leads to higher motivation",
    comment:
      "Like the name already indicates, the community focus is really strong. You don’t only get notes from others, but you also start feeling an urge to contribute to this amazing collection, which leads to higher motivation for your own studying and note-taking.",
  },
  {
    id: 3,
    cus_avt: customer3,
    impress_text: "I’ve taken inspiration from some users",
    comment:
      "Personally I find it really interesting to see how other people use Journal & Planner, I’ve taken inspiration from some users and added them into my own notes.",
  },
];

const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    width: 440px !important;
    box-shadow: none !important;
  }

  .swiper-slide:last-child {
    margin-right: 0 !important;
  }

  @media only screen and (min-width: 320px) and (max-width: 598px) {
    .swiper-slide {
      width: 315px !important;
    }

    .swiper-wrapper {
      gap: 16px;
    }
  }
`;

const CustomerReview = () => {
  return (
    <section className={styles["customer__review"]}>
      <h1 className={styles["customer__review--title"]}>
        What current Journal and Planner community members have to say...
      </h1>
      <div className={styles["customer__review--list"]}>
        <StyledSwiper
          autoplay={{
            delay: 2500,
          }}
          loop={true}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 138,
            },
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {reviewData.map((review) => (
            <SwiperSlide key={review.id}>
              <div className={styles["review__item"]}>
                <div className={styles["review__item--customer"]}>
                  <div className={styles["customer__avt"]}>
                    <img src={review.cus_avt} alt="" />
                  </div>
                  <p>“{review.impress_text}”</p>
                </div>
                <p className={styles["review__item--comment"]}>
                  “{review.comment}”
                </p>
              </div>
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </div>
    </section>
  );
};

export default CustomerReview;
